import React from 'react';
import styles from './index.module.scss';
import Link from 'next/link';
import { clsx } from '@mantine/core';

export interface GameButtonProps {
  text: string;
  link: string;
  className?: string;
  isDisabled?: boolean;
  isComingSoon?: boolean;
}
const GameButton = ({ text, link, className, isDisabled, isComingSoon }: GameButtonProps) => {
  return (
    <>
      {isDisabled ? (
        <div
          className={clsx(
            '!cursor-default min-w-[260px] lg:min-w-[311px]',
            isComingSoon ? 'uppercase text-[16px] font-bold py-3' : styles[className],
          )}
        >
          {text}
        </div>
      ) : (
        <Link passHref href={link} target='_blank'>
          <a target='_blank'>
            <div className={clsx('min-w-[260px] lg:min-w-[311px] text-center', styles[className], styles['animate-gradient'])}>{text}</div>
          </a>
        </Link>
      )}
    </>
  );
};

export default GameButton;
